import { Button, THEME } from "@myloc/myloc-gui";
import QRCode from "qrcode.react";
import { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../reducers/rootReducer";
import pages from "../../../utils/pages";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { ReactComponent as BankIDIcon } from "./assets/images/BankID_logo_black.svg";
import { useBankId } from "./hooks/useBankId";
import type { CollectedResponse } from "./hooks/useBankIdAuthentication";
import styles from "./style/bankId.module.scss";

interface SpinnerProps {
  hide: boolean;
}

const Spinner = ({ hide }: SpinnerProps) => {
  return <div className="spinnerPlaceholder">{hide ? <>Hiding</> : <LoadingSpinner />}</div>;
};

interface FailedContentProps {
  collectedResponse?: CollectedResponse;
  action: () => void;
  cancelled?: boolean;
}

const FailedContent = ({ collectedResponse, action, cancelled }: FailedContentProps) => {
  const getMessage = () => {
    if (cancelled) {
      return "Inloggningen avbröts";
    }

    if (collectedResponse?.status === "failed" && collectedResponse.hintCode === "startFailed") {
      return "Inloggningen startades inte";
    }

    if (collectedResponse?.status === "failed") {
      return "Autenticeringen misslyckades";
    }

    return "Ett fel uppstod";
  };

  return (
    <div>
      <p>{getMessage()}</p>
      <Button theme={THEME.PLAIN_PRIMARY} onClick={action} customCssClass={undefined} isLoading={undefined}>
        Försök igen
      </Button>
    </div>
  );
};

interface PendingContentProps {
  collectedResponse?: CollectedResponse; // collectedResponse is optional
}

const PendingContent = ({ collectedResponse }: PendingContentProps) => {
  let msg: string;

  if (!collectedResponse) {
    msg = "Väntar på BankID...";
  } else if (collectedResponse.status === "complete") {
    msg = "Inloggning lyckades, omdirigerar...";
  } else if (collectedResponse.status === "failed") {
    msg = "Inloggning misslyckades...";
  } else if (collectedResponse.hintCode === "userSign") {
    msg = "Väntar på inloggning...";
  } else {
    msg = "Oväntat fel!";
  }

  return (
    <>
      <Spinner hide={false} />
      <>{msg}</>
    </>
  );
};

interface InitContentProps {
  qrCodeContent: string;
  autoStartToken: string;
  orderRef: string;
}

const InitContent = ({ qrCodeContent, autoStartToken, orderRef }: InitContentProps) => {
  return (
    <>
      <ol>
        <li>1. Öppna BankID-appen i mobilen.</li>
        <li>2. Tryck på QR-symbolen i BankID-appen.</li>
        <li>3. Rikta kameran mot QR-koden i denna ruta.</li>
      </ol>
      <div className={styles.content}>{qrCodeContent && <QRCode size={128} value={qrCodeContent} />}</div>
      <div className={styles.content}>
        <a
          href={`bankid:///?autostarttoken=${autoStartToken}&redirect=https%3A%2F%2F${window.location.hostname}%2F%3FloginToken%3D${orderRef}`}
        >
          Logga in på denna enhet
        </a>
      </div>
    </>
  );
};

const navigateHome = () => {
  window.location.href = pages.HOME;
};

const CancelContent = () => {
  return (
    <div className={styles.cancelContent} onClick={navigateHome}>
      Tillbaka
    </div>
  );
};

interface BankIdContainerProps {
  children: ReactNode;
}

const BankIdContainer = ({ children }: BankIdContainerProps) => {
  return (
    <div className={styles.authenticationContainer}>
      <div className={styles.bankIDContainerTitle}>
        <BankIDIcon className={styles.titleIcon} />
        <div className="bankIDHeading">Logga in med BankID</div>
      </div>
      {children}
      <CancelContent />
    </div>
  );
};

// Main component
const BankIdAuthentication = () => {
  const initialized = useSelector((state: RootState) => state.session.initialized);
  const {
    autoStartToken,
    cancelled,
    collectedResponse,
    initAuth,
    isBankIdError,
    orderRef,
    qrCodeContent,
    restart,
  } = useBankId();

  useEffect(() => {
    if (!autoStartToken && initialized && !cancelled) {
      initAuth();
    }
  }, [autoStartToken, cancelled, initAuth, initialized]);

  return (
    <div className={styles.container}>
      <BankIdContainer>
        {cancelled || isBankIdError() ? (
          <FailedContent action={restart} />
        ) : !autoStartToken ? (
          <PendingContent />
        ) : qrCodeContent && collectedResponse?.hintCode === "outstandingTransaction" ? (
          <InitContent qrCodeContent={qrCodeContent} autoStartToken={autoStartToken} orderRef={orderRef} />
        ) : collectedResponse?.hintCode === "expiredTransaction" || collectedResponse?.status === "failed" ? (
          <FailedContent collectedResponse={collectedResponse} action={restart} />
        ) : (
          <PendingContent collectedResponse={collectedResponse} />
        )}
      </BankIdContainer>
    </div>
  );
};

export default BankIdAuthentication;
