//import { useHistory } from "react-router-dom";
import pages from "../../../utils/pages";
import { ReactComponent as BankIDIcon } from "./assets/images/BankID_logo_black.svg";
import styles from "./style/bankId.module.scss";

// UI components
const BankIdButton = () => {
  //const history = useHistory();

  const navigateToBankId = () => {
    //history.push(pages.BANKID_LOGIN.PATH);
    window.location.href = pages.BANKID_LOGIN.PATH;
  };

  return (
    <div className={styles.content}>
      <button className={styles.button} onClick={navigateToBankId}>
        <BankIDIcon className={styles.icon} />
        <span className={styles.text}>Logga in med BankID</span>
      </button>
    </div>
  );
};

export default BankIdButton;
