import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers/rootReducer";
import { useBankIDCollect, useBankIdStartAuthentication, useQRCode } from "./useBankIdAuthentication";

export const useBankId = () => {
  const [bankIdError, setBankIdError] = useState<string | undefined>(undefined);
  const [cancelled, setCancelled] = useState<boolean>(false);

  const {
    orderRef,
    autoStartToken,
    handleAuth,
    bankIdContent,
    resetAuth,
    resetBankIdContent,
  } = useBankIdStartAuthentication(setBankIdError);

  const { qrCodeContent, stopQRGeneration } = useQRCode(bankIdContent);
  const { collectedResponse, resetCollect } = useBankIDCollect(bankIdContent, setBankIdError);

  const sessionId = useSelector((state: RootState) => state.session.sessionId);

  const restart = () => {
    reset();
    resetAuth();
  };

  const reset = useCallback(() => {
    resetBankIdContent();
    resetCollect();
    //resetAuth();
    stopQRGeneration;
    setCancelled(false);
  }, [resetBankIdContent, resetCollect, stopQRGeneration]);

  useEffect(() => {
    if (sessionId) {
      setCancelled(true);
    }
  }, [sessionId]);

  useEffect(() => {
    if (bankIdError) {
      reset();
    }
  }, [bankIdError, reset]);

  useEffect(() => {
    if (!collectedResponse) return;

    if (collectedResponse.hintCode === "userSign" || collectedResponse.status !== "pending") {
      stopQRGeneration();
    }
  }, [collectedResponse, stopQRGeneration]);

  const initAuth = () => {
    if (bankIdError) {
      setBankIdError(undefined);
      //setCancelled(true);
    }

    handleAuth();
  };

  const isBankIdError = () => !!bankIdError;

  return {
    isBankIdError,
    cancelled,
    orderRef,
    autoStartToken,
    initAuth,
    qrCodeContent,
    collectedResponse,
    restart,
    reset,
  };
};
