import { PayloadAction } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import storage from "../../utils/store/storage";
import {
  LOGIN_TOKEN as STORE_LOGIN_TOKEN,
  REMEMBER_ME as STORE_REMEMBER_ME,
  SESSION_ID as STORE_SESSION_ID,
} from "../../utils/store/storeVariables";
import store, { RootState } from "../rootReducer";

import type { ApplicationItem } from "./sessionReducer";
import {
  APPLICATIONS_LIST,
  AUTHORIZATION_LIST,
  COMFLOW_SESSION_ID,
  INITIALIZED,
  IS_SINGLE_SIGN_ON,
  LOGIN_TOKEN,
  REMEMBER_ME,
  SESSION_ID,
  SINGLE_SIGN_OUT,
  SSO_SESSION_DATA,
  USER_HAS_MESSAGE_TO_ACCEPT,
} from "./sessionVariables";

// Action creators

export function resetComflowSessionId(): AnyAction {
  return { type: COMFLOW_SESSION_ID, undefined };
}

export function setSessionId(sessionId: string | null, rememberMe: boolean): void {
  saveItem(STORE_SESSION_ID, sessionId, rememberMe);
  store.dispatch({ type: SESSION_ID, sessionId });
}

export function setComflowSessionId(comflowSessionId: string): PayloadAction<string> {
  return {
    type: COMFLOW_SESSION_ID,
    payload: comflowSessionId,
  };
}

export function setApplicationsList(applicationsList: ApplicationItem[]): PayloadAction<ApplicationItem[]> {
  return {
    type: APPLICATIONS_LIST,
    payload: applicationsList,
  };
}

export function setAuthorizationList(authorizationList: any): void {
  store.dispatch({ type: AUTHORIZATION_LIST, authorizationList });
}

export function setRememberMe(rememberMe: boolean): AnyAction {
  saveItem(STORE_REMEMBER_ME, rememberMe, rememberMe);

  return { type: REMEMBER_ME, rememberMe };
}

export function setLoginToken(loginToken: string | null, rememberMe: boolean): void {
  saveItem(STORE_LOGIN_TOKEN, loginToken, rememberMe);
  store.dispatch({ type: LOGIN_TOKEN, loginToken });
}

export function resetSession(): void {
  const state: RootState = store.getState();
  const rememberMe = state.session.rememberMe;

  storage.saveItem(STORE_SESSION_ID, null, rememberMe);

  const sessionId = null;

  return setSessionId(sessionId, false);
}

export function setInitialized(initialized = true): void {
  store.dispatch({ type: INITIALIZED, initialized });
}

export function setUserHasMessageToAccept(message: string): void {
  store.dispatch({ type: USER_HAS_MESSAGE_TO_ACCEPT, message });
}

function saveItem(variableId: string, value: any, rememberMe: boolean): void {
  storage.saveItem(variableId, value, rememberMe);
}

/** call with dispatch() to handle correct typing */
export function setSSOSessionData(ssoSessionData: any): AnyAction {
  return {
    type: SSO_SESSION_DATA,
    sessionData: ssoSessionData,
  };
}

/** call with dispatch() to handle correct typing */
export function setIsSingleSignOn(isSingleSignOn: boolean): AnyAction {
  return {
    type: IS_SINGLE_SIGN_ON,
    isSingleSignOn,
  };
}

/** call with dispatch() to handle correct typing */
export function setSingleSignOut(): AnyAction {
  return {
    type: SINGLE_SIGN_OUT,
    singleSignOut: true,
  };
}
