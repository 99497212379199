import { withPage } from "@myloc/myloc-utils";
import PropType from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, Route, Switch, useParams } from "react-router-dom";
import { useTranslate } from "../../language/i18n";
import orderService from "../../services/order/orderService";
import { COMMENT_TYPE } from "../../utils/constants";
import pages from "../../utils/pages";
import Page from "../shared/Page/Page";
import SubPageMenu from "../shared/SubPageMenu/SubPageMenu";
import Comments from "./Comments/Comments";
import styles from "./FittingDetailPage.module.scss";
import FittingOrders from "./Orders/FittingOrders";
import Files from "./Sections/Files";
import FittingInfo from "./Sections/FittingInfo";
import NextMeeting from "./Sections/NextMeeting/NextMeeting";
import OtherInfo from "./Sections/OtherInfo";
import PreviousMeeting from "./Sections/PreviousMeeting";
import UserAndContact from "./Sections/UserAndContact";

function FittingDetailPage({ referrer }) {
  const params = useParams();
  const translate = useTranslate();
  const embeddedFitting = useSelector(state => state?.embedded?.fitting);
  const isEmbedded = Boolean(window.location.pathname.match(/^\/embedded\//));
  const [fitting, setFitting] = useState(null);
  const [fetchFittings, setFetchFittings] = useState(false);
  const queryParams = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      text: !referrer?.text
        ? `${translate(pages.MY_MYLOC.NAME)} - ${translate(pages.FITTINGS.NAME)}`
        : translate(referrer.text),
      link: referrer?.path || pages.MY_FITTINGS.PATH,
    },
    {
      text: `${translate(pages.FITTING_DETAIL.NAME)} - ${translate(pages.MY_FITTINGS_INFO.NAME)}`,
    },
  ]);

  useEffect(() => {
    orderService.getFitting(queryParams.fitting).then(response => {
      setFitting(response.data);
    });
    if (fetchFittings) setFetchFittings(false);
  }, [queryParams.fitting, fetchFittings]);

  const pageDescription = `${fitting?.contentNumber} / ${fitting?.fittingType?.name}`;

  const onCommentCreated = (type, newComment) => {
    setFitting(prev => {
      switch (type) {
        case COMMENT_TYPE.INTERNAL: {
          const fitting = { ...prev };
          fitting.fittingComments.internalComments = [...prev.fittingComments.internalComments, newComment];
          return fitting;
        }
        case COMMENT_TYPE.EXTERNAL: {
          const fitting = { ...prev };
          fitting.fittingComments.externalComments = [...prev.fittingComments.externalComments, newComment];
          return fitting;
        }
      }
    });
  };

  const onCommentDeleted = (type, deletedCommentId) => {
    setFitting(prev => {
      switch (type) {
        case COMMENT_TYPE.INTERNAL: {
          const fitting = { ...prev };
          const internalComments = fitting.fittingComments.internalComments;
          const index = internalComments.findIndex(c => c.id === deletedCommentId);
          internalComments.splice(index, 1);
          return fitting;
        }
        case COMMENT_TYPE.EXTERNAL: {
          const fitting = { ...prev };
          const externalComments = fitting.fittingComments.externalComments;
          const index = externalComments.findIndex(c => c.id === deletedCommentId);
          externalComments.splice(index, 1);
          return fitting;
        }
      }
    });
  };

  if (!fitting) {
    return <></>;
  }

  const links = [
    {
      NAME: pages.MY_FITTINGS_INFO.NAME,
      PATH: generatePath(isEmbedded ? "/embedded" + pages.MY_FITTINGS_INFO.PATH : pages.MY_FITTINGS_INFO.PATH, {
        fitting: params.fitting,
      }),
    },
    {
      NAME: pages.MY_FITTINGS_USER_AND_CONTACT.NAME,
      PATH: generatePath(
        isEmbedded ? "/embedded" + pages.MY_FITTINGS_USER_AND_CONTACT.PATH : pages.MY_FITTINGS_USER_AND_CONTACT.PATH,
        { fitting: params.fitting },
      ),
    },
    {
      NAME: pages.MY_FITTINGS_NEXT_MEETING.NAME,
      PATH: generatePath(
        isEmbedded ? "/embedded" + pages.MY_FITTINGS_NEXT_MEETING.PATH : pages.MY_FITTINGS_NEXT_MEETING.PATH,
        { fitting: params.fitting },
      ),
    },
    {
      NAME: pages.MY_FITTINGS_PREVIOUS_MEETING.NAME,
      PATH: generatePath(
        isEmbedded ? "/embedded" + pages.MY_FITTINGS_PREVIOUS_MEETING.PATH : pages.MY_FITTINGS_PREVIOUS_MEETING.PATH,
        { fitting: params.fitting },
      ),
    },
    {
      NAME: pages.MY_FITTINGS_OTHER_INFO.NAME,
      PATH: generatePath(
        isEmbedded ? "/embedded" + pages.MY_FITTINGS_OTHER_INFO.PATH : pages.MY_FITTINGS_OTHER_INFO.PATH,
        { fitting: params.fitting },
      ),
    },
    {
      NAME: pages.MY_FITTINGS_ATTACHMENTS.NAME,
      PATH: generatePath(
        isEmbedded ? "/embedded" + pages.MY_FITTINGS_ATTACHMENTS.PATH : pages.MY_FITTINGS_ATTACHMENTS.PATH,
        { fitting: params.fitting },
      ),
    },
    {
      NAME: pages.MY_FITTINGS_ORDERS.NAME,
      PATH: generatePath(isEmbedded ? "/embedded" + pages.MY_FITTINGS_ORDERS.PATH : pages.MY_FITTINGS_ORDERS.PATH, {
        fitting: params.fitting,
      }),
    },
    {
      NAME: pages.MY_FITTINGS_INTERNAL_COMMENTS.NAME,
      PATH: generatePath(
        isEmbedded ? "/embedded" + pages.MY_FITTINGS_INTERNAL_COMMENTS.PATH : pages.MY_FITTINGS_INTERNAL_COMMENTS.PATH,
        {
          fitting: params.fitting,
        },
      ),
      HIDDEN: fitting.fittingComments.internalComments == null,
    },
    {
      NAME: pages.MY_FITTINGS_EXTERNAL_COMMENTS.NAME,
      PATH: generatePath(
        isEmbedded ? "/embedded" + pages.MY_FITTINGS_EXTERNAL_COMMENTS.PATH : pages.MY_FITTINGS_EXTERNAL_COMMENTS.PATH,
        {
          fitting: params.fitting,
        },
      ),
      HIDDEN: fitting.fittingComments.externalComments == null,
    },
  ];

  return (
    <Page
      title={translate(pages.FITTING_DETAIL.NAME)}
      description={pageDescription}
      customCssClass={styles.page}
      breadcrumbs={breadcrumbs}
      showRefreshButton={isEmbedded}
    >
      <div className={styles.client}>
        {`${fitting?.client?.firstName} ${fitting?.client?.surname} - ${fitting?.client?.identityNumber}`}
      </div>

      <div className={styles.pageContent}>
        <SubPageMenu links={links} />
        <Switch>
          <Route exact path={[pages.MY_FITTINGS_INFO.PATH, "/embedded" + pages.MY_FITTINGS_INFO.PATH]}>
            <FittingInfo
              fitting={fitting}
              setBreadcrumbs={setBreadcrumbs}
              embeddedFitting={isEmbedded ? embeddedFitting : ""}
              setFetchFittings={setFetchFittings}
            />
          </Route>

          <Route
            exact
            path={[pages.MY_FITTINGS_USER_AND_CONTACT.PATH, "/embedded" + pages.MY_FITTINGS_USER_AND_CONTACT.PATH]}
          >
            <UserAndContact
              fitting={fitting}
              setFetchFittings={setFetchFittings}
              embeddedFitting={isEmbedded ? embeddedFitting : ""}
              setBreadcrumbs={setBreadcrumbs}
            />
          </Route>

          <Route exact path={[pages.MY_FITTINGS_NEXT_MEETING.PATH, "/embedded" + pages.MY_FITTINGS_NEXT_MEETING.PATH]}>
            <NextMeeting
              fitting={fitting}
              setBreadcrumbs={setBreadcrumbs}
              embeddedFitting={isEmbedded ? embeddedFitting : ""}
              setFetchFittings={setFetchFittings}
            />
          </Route>

          <Route exact path={[pages.MY_FITTINGS_OTHER_INFO.PATH, "/embedded" + pages.MY_FITTINGS_OTHER_INFO.PATH]}>
            <OtherInfo
              fitting={fitting}
              setBreadcrumbs={setBreadcrumbs}
              embeddedFitting={isEmbedded ? embeddedFitting : ""}
            />
          </Route>

          <Route
            exact
            path={[pages.MY_FITTINGS_PREVIOUS_MEETING.PATH, "/embedded" + pages.MY_FITTINGS_PREVIOUS_MEETING.PATH]}
          >
            <PreviousMeeting
              fitting={fitting}
              setBreadcrumbs={setBreadcrumbs}
              embeddedFitting={isEmbedded ? embeddedFitting : ""}
              setFetchFittings={setFetchFittings}
            />
          </Route>
          <Route exact path={[pages.MY_FITTINGS_ATTACHMENTS.PATH, "/embedded" + pages.MY_FITTINGS_ATTACHMENTS.PATH]}>
            <Files
              files={fitting?.files}
              setBreadcrumbs={setBreadcrumbs}
              embeddedFitting={isEmbedded ? embeddedFitting : ""}
              setFetchFittings={setFetchFittings}
              isCompleted={fitting?.status?.value === 90 || fitting?.status?.value === 99}
            />
          </Route>
          <Route exact path={[pages.MY_FITTINGS_ORDERS.PATH, "/embedded" + pages.MY_FITTINGS_ORDERS.PATH]}>
            <FittingOrders
              fitting={fitting}
              setBreadcrumbs={setBreadcrumbs}
              embeddedFitting={isEmbedded ? embeddedFitting : ""}
            />
          </Route>
          {fitting.fittingComments.internalComments != null && (
            <Route
              exact
              path={[pages.MY_FITTINGS_INTERNAL_COMMENTS.PATH, "/embedded" + pages.MY_FITTINGS_INTERNAL_COMMENTS.PATH]}
            >
              <Comments
                fittingId={fitting.id}
                comments={fitting.fittingComments.internalComments}
                type={COMMENT_TYPE.INTERNAL}
                setFetchFittings={setFetchFittings}
              />
            </Route>
          )}
          {fitting.fittingComments.externalComments != null && (
            <Route
              exact
              path={[pages.MY_FITTINGS_EXTERNAL_COMMENTS.PATH, "/embedded" + pages.MY_FITTINGS_EXTERNAL_COMMENTS.PATH]}
            >
              <Comments
                fittingId={fitting.id}
                comments={fitting.fittingComments.externalComments}
                type={COMMENT_TYPE.EXTERNAL}
                onCommentCreated={onCommentCreated}
                onCommentDeleted={onCommentDeleted}
              />
            </Route>
          )}
        </Switch>
      </div>
    </Page>
  );
}

FittingDetailPage.propTypes = {
  referrer: PropType.object,
};

export default withPage(FittingDetailPage);
