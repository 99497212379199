// LoginWelcome.js
import { Button, THEME } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useEffect } from "react";

import pages from "../../../utils/pages";
import BankIdAuthentication from "../../shared/BankId/BankIdAuthentication";
import BankIdButton from "../../shared/BankId/BankIDButton";
import Login from "../Login/Login";
import styles from "../Login/Login.module.scss";
import useAvailableLoginMethods, { LOGIN_METHODS } from "./hooks/useAvailableLoginMethods";
import useSelectedLoginMethod from "./hooks/useSelectedLoginMethod";
import Welcome from "./Welcome";

const LoginFormSelectorButton = () => (
  <div className={styles.loginFormSelectorButton}>
    <Button> Logga in med användarnamn</Button>
  </div>
);

const LoginFormLogin = ({ isSelected, onSelect, ...props }) => (
  <div className={styles.loginFormContainer} onClick={onSelect}>
    {isSelected ? (
      <Login {...props} registerUrl={pages.REGISTER} forgotPasswordUrl={"/"} />
    ) : (
      <LoginFormSelectorButton />
    )}
  </div>
);
LoginFormLogin.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const BankIdLogin = ({ isSelected, onSelect }) => (
  <div onClick={onSelect}>
    <BankIdAuthentication isSelected={isSelected} />
  </div>
);
BankIdLogin.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const Logins = ({ availableLoginMethods, isLoginFormSelected, isBankIDSelected, setSelectedLoginMethod, ...props }) => {
  return (
    <>
      {availableLoginMethods.map((method, index) => {
        if (method === LOGIN_METHODS.BANKID && !isLoginFormSelected()) {
          return <BankIdButton key={`LoginForm-${index}`} />;
        } else if (method === LOGIN_METHODS.LOGIN_FORM && !isBankIDSelected()) {
          return (
            <LoginFormLogin
              key={`LoginForm-${index}`}
              isSelected={isLoginFormSelected()}
              onSelect={() => setSelectedLoginMethod(LOGIN_METHODS.LOGIN_FORM)}
              {...props}
            />
          );
        }
        return null;
      })}
    </>
  );
};
Logins.propTypes = {
  availableLoginMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoginFormSelected: PropTypes.func.isRequired,
  isBankIDSelected: PropTypes.func.isRequired,
  setSelectedLoginMethod: PropTypes.func.isRequired,
};

const LoginMethodsContainer = ({ availableLoginMethods, reset, selectedLoginMethod, ...props }) => (
  <>
    <Logins availableLoginMethods={availableLoginMethods} {...props} />
    {selectedLoginMethod && availableLoginMethods.length > 1 && (
      <div className={styles.loginFormContainer} onClick={reset}>
        <Button theme={THEME.PLAIN_PRIMARY} customCssClass={styles.passwordButton}>
          Ändra inloggningssätt
        </Button>
      </div>
    )}
  </>
);
LoginMethodsContainer.propTypes = {
  availableLoginMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  reset: PropTypes.func.isRequired,
  selectedLoginMethod: PropTypes.string,
};

const LoginWelcome = ({ ...props }) => {
  const { availableLoginMethods, addLoginMethod, checkValidLoginMethods } = useAvailableLoginMethods();
  const {
    selectedLoginMethod,
    activateAndSelectLoginMethod,
    reset,
    isLoginFormSelected,
    isBankIDSelected,
  } = useSelectedLoginMethod(addLoginMethod);

  useEffect(() => {
    checkValidLoginMethods();
  }, [checkValidLoginMethods]);

  return (
    <Welcome {...props}>
      <LoginMethodsContainer
        availableLoginMethods={availableLoginMethods}
        reset={reset}
        selectedLoginMethod={selectedLoginMethod}
        setSelectedLoginMethod={activateAndSelectLoginMethod}
        isLoginFormSelected={isLoginFormSelected}
        isBankIDSelected={isBankIDSelected}
        {...props}
      />
    </Welcome>
  );
};

LoginWelcome.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  customCssClass: PropTypes.string,
};

export default LoginWelcome;
