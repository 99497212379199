import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../../assets/logos/Myloc-logga.svg";
import { useTranslate } from "../../../language/i18n";
import { RootState } from "../../../reducers/rootReducer";
//import { ApplicationItem } from "../../../reducers/session/sessionReducer";
import { LoadingButton } from "@mui/lab";
import colors from "../../../style/exports.module.scss";
import page from "../../../utils/pages";
import useComflowLogin from "../../Comflow/hooks/useComflowLogin";
import MessageButton from "../../Message/MessageButton";
import CartButton from "./CartButton/CartButton";
import styles from "./Header.module.scss";
import Menu from "./Menu/Menu";
import MyMylocMenu from "./MyMylocMenu/MyMylocMenu";
import OrderMenu from "./OrderMenu/OrderMenu";
import ReceiverMenu from "./ReceiverMenu/ReceiverMenu";

function Header() {
  const userLoggedIn = !!useSelector((state: RootState) => state.session.sessionId);
  const order = useSelector((state: RootState) => state.appData.order);
  const applicationsList = useSelector((state: RootState) => state.session.applicationsList);

  const translate = useTranslate();

  const comflowApp = applicationsList?.find(app => app.id === "COMFLOW");

  const LinkToComflow: React.FC = () => {
    const { isLoggedIn, initiateLogin } = useComflowLogin();
    const [loading, setLoading] = useState<boolean>(false);
    const message = useSelector((state: RootState) => state.dialog.message);

    useEffect(() => {
      if (message?.error) {
        setLoading(false);
      }
    }, [message]);

    useEffect(() => {
      if (isLoggedIn) {
        setLoading(false);
        window.open(process.env.REACT_APP_COMFLOW_WEBAPP, "_blank");
      }
    }, [isLoggedIn]);

    const handleClick = () => {
      initiateLogin();
      setLoading(true);
    };

    return (
      <LoadingButton
        variant="outlined"
        style={{
          color: colors.iconColor,
          borderColor: colors.iconColor,
        }}
        endIcon={<OpenInNewIcon />}
        onClick={handleClick}
        loading={loading}
        loadingPosition="end"
      >
        Öppna {comflowApp?.label}
      </LoadingButton>
    );
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <h1 className={styles.hidden}>{translate("SITE_TITLE")}</h1>
        <Link to={page.HOME}>
          <img src={logo} alt="Myloc logo" className={styles.logo} />
        </Link>
        {comflowApp && <LinkToComflow />}
        {userLoggedIn && (
          <div className={styles.rightMenu}>
            <MessageButton />
            <MyMylocMenu />
            {order?.receiver && <ReceiverMenu />}
            <OrderMenu />
            <CartButton />
          </div>
        )}
      </div>
      <Menu userLoggedIn={userLoggedIn} />
    </header>
  );
}

export default Header;
