import { Method, Request, Response, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import i18n from "../../language/i18n";
//import { clearAppData, setCompany, setFacility, setInformation, setOrder } from "../../reducers/appData/appDataActions";
import { clearAppData } from "../../reducers/appData/appDataActions";
import { setError } from "../../reducers/dialog/dialogAction";
import {
  resetSession,
  setAuthorizationList,
  //setInitialized,
  setSessionId,
} from "../../reducers/session/sessionAction";
import { getErrorMessage } from "../error/errorService";
import defaultRestOptions from "../utils/defaultRestOptions";

// POST /register
//TODO Is used?
async function register(newUser) {
  return await sendRequest(new Request(api.account.register()), newUser, await defaultRestOptions());
}

// POST /login
async function login(user) {
  const request = new Request(api.account.login(), Method.POST);
  const response = await sendRequest(request, user, await defaultRestOptions());

  if (response.isOk()) {
    setSessionId(response.data.id);
    setAuthorizationList(response.data.authorizations);
  }

  return response;
}

async function loginWithToken(data) {
  const request = new Request(api.account.login(), Method.POST);
  //Don't send default options since it includes sessionId in header - if it exists from a previous session
  //Since we're at a login with Myloc token it is safe(?) to renew the session - or perhaps we should perform a logout first!?
  try {
    const response = await sendRequest(request, data);

    if (response.isOk() && response.data?.id) {
      setSessionId(response.data.id);
    } else {
      let msg;
      if (typeof response.message === "string") {
        msg = response.message;
      } else {
        msg = response.message.text;
      }
      setError({ error: "exception", errorMessage: msg });
    }

    return response;
  } catch (e) {
    console.log("Error: ", e);
    throw e;
  }
}

async function getAppData() {
  const request = new Request(api.account.appData(), Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function logout(isSingleSignOn) {
  const request = new Request(api.account.logout(), Method.DELETE);
  const response = await sendRequest(request, null, await defaultRestOptions());

  if (response.isOk() || window.location.host === "localhost:3000") {
    resetSession();
    clearAppData();
    if (isSingleSignOn) {
      const domain = window.location.hostname;
      const redirectLocation =
        "/MyL/redirect_uri?logout=https%3A%2F%2F" + domain + encodeURIComponent("/MyL") + "%2Flogged-out.html";
      window.location = redirectLocation;
    } else {
      window.location = "/";
    }
  }

  return response;
}

async function updateAccountSettings(data) {
  const url = api.account.settings();
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function updateStandardLocation(data) {
  const url = api.account.location();
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function updatePassword(resetToken, data) {
  const url = api.account.password();
  const request = new Request(url, Method.PUT);
  let options = await defaultRestOptions();
  return await sendRequest(request, { ...data, token: resetToken }, options);
}

async function getPasswordPolicy() {
  const url = api.account.passwordRules();
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function resetPassword(data) {
  /**
   * Use a custom error handler to set a better descrption than sent from backend
   * The user sholud not see "not authorized" if user does not exist
   */
  const customErrorHandler = exception => {
    let status;
    let message;
    if (exception?.response?.status === 401) {
      //A non existing user has requested a password reset. Show standard message anyway together with status 200
      //Need to create message as object to distinguish from ordinary message when returned to page
      message = {
        text: i18n.t("RESET_LINK_IS_SENT"),
        isCustomHandled: true,
      };
      status = 200;
    }

    //If no custom message is set, use message from exception
    if (!message) {
      message = getErrorMessage(exception);
    }

    const responseStatus = status ? status : exception?.response?.status;
    return new Response(responseStatus, message, exception);
  };

  const url = api.account.reset();
  const request = new Request(url, Method.POST);

  return await sendRequest(request, data, await defaultRestOptions(customErrorHandler));
}

async function confirmAccessMessage(data) {
  const url = api.account.confirmAccessMessage();
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

//Set and get how the user wants the product page to be displayed
async function setUserSetting(id, data) {
  const url = api.account.setUserSetting(id);
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}
async function getUserSetting(id) {
  const customErrorHandler = exception => {
    // a user with no settings has requested a setting. Show standard message anyway together with status 200
    if (exception?.response?.status === 404) {
      message = {
        text: "",
        isCustomHandled: true,
      };
      status = 200;
    }

    let status;
    let message;
    //If no custom message is set, use message from exception
    if (!message) {
      message = getErrorMessage(exception);
    }

    const responseStatus = status ? status : exception?.response?.status;
    return new Response(responseStatus, message, exception);
  };

  const url = api.account.getUserSetting(id);
  const request = new Request(url, Method.POST);
  return await sendRequest(request, {}, await defaultRestOptions(customErrorHandler));
}

export default {
  register,
  login,
  loginWithToken,
  logout,
  getAppData,
  updateAccountSettings,
  setUserSetting,
  getUserSetting,
  updateStandardLocation,
  getPasswordPolicy,
  updatePassword,
  resetPassword,
  confirmAccessMessage,
};
