import CryptoJS from "crypto-js";
import { useCallback, useEffect, useRef, useState } from "react";
import { collect, initiateAuth, OrderRef } from "../services/bankIdService";

// Define types for the response data from BankID service
interface BankIdResponseData {
  orderRef: string;
  autoStartToken: string;
  qrStartSecret: string;
  qrStartToken: string;
  receivedTime: number;
}

interface BankIdResponse {
  isOk: () => boolean;
  message: string | { text: string };
  data?: string | BankIdResponseData | unknown;
}

// Define types for collected response
export interface CollectedResponse {
  status: string;
  hintCode: string;
}

// Runtime type guard to check if the data is BankIdResponseData
const isBankIdResponseData = (data: any): data is BankIdResponseData => {
  return (
    typeof data === "object" &&
    data !== null &&
    "orderRef" in data &&
    "autoStartToken" in data &&
    "qrStartSecret" in data &&
    "qrStartToken" in data &&
    "receivedTime" in data
  );
};

const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;

// Hook for starting BankID authentication
export const useBankIdStartAuthentication = (setBankIdError: (error: string) => void) => {
  const [orderRef, setOrderRef] = useState<string>("");
  const refAuthInitialized = useRef<boolean>(false);
  const [autoStartToken, setAutoStartToken] = useState<string>("");
  const [bankIdContent, setBankIdContent] = useState<BankIdResponseData | undefined>();

  const resetAuth = () => {
    setAutoStartToken("");
    refAuthInitialized.current = false;
  };

  const handleAuth = async () => {
    if (refAuthInitialized.current) {
      return;
    }

    refAuthInitialized.current = true;

    const response: BankIdResponse = await initiateAuth();

    if (!response.isOk()) {
      if (typeof response.message === "string") {
        setBankIdError(response.message);
      }

      return;
    }

    // Validate that response.data matches BankIdResponseData
    const data = response.data;

    if (isBankIdResponseData(data)) {
      setBankIdContent(data);
      setOrderRef(orderRef);
      setAutoStartToken(data.autoStartToken);
    } else {
      setBankIdError("Invalid response data");
    }
  };

  const resetBankIdContent = () => {
    setBankIdContent(undefined);
  };

  return { orderRef, autoStartToken, bankIdContent, handleAuth, resetAuth, resetBankIdContent };
};

// Hook for generating QR code
export const useQRCode = (bankIdContent: BankIdResponseData | undefined) => {
  const [qrCodeContent, setQrCodeContent] = useState<string>("");
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const stopQRGeneration = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      setQrCodeContent("");
    }
  }, []);

  const bankIdGenerateQRCode = useCallback(
    (time: number) => {
      if (!bankIdContent) return;

      const qrAuthCode = CryptoJS.HmacSHA256("" + time, bankIdContent.qrStartSecret);
      const qrContent = `bankid.${bankIdContent.qrStartToken}.${time}.${qrAuthCode}`;

      setQrCodeContent(qrContent);
    },
    [bankIdContent],
  );

  useEffect(() => {
    if (!bankIdContent) return;

    const nowMS = new Date().getTime();
    const timeFromReceivedMS = nowMS - bankIdContent.receivedTime;
    let timeFromReceived = Math.round(timeFromReceivedMS / MILLISECONDS_IN_SECOND);

    intervalRef.current = setInterval(() => {
      bankIdGenerateQRCode(timeFromReceived);
      timeFromReceived += 1;

      if (timeFromReceived > SECONDS_IN_MINUTE) {
        stopQRGeneration();
      }
    }, MILLISECONDS_IN_SECOND);

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [bankIdContent, bankIdGenerateQRCode, stopQRGeneration]);

  return { qrCodeContent, stopQRGeneration };
};

/*
// Initial values for the collected response state
const collectedResponseInitialValues: CollectedResponse = {
  status: "",
  hintCode: "",
};
*/
// Hook for collecting BankID status
export const useBankIDCollect = (
  bankIdContent: BankIdResponseData | undefined,
  setBankIdError: (error: string) => void,
) => {
  const [collectedResponse, setCollectedResponse] = useState<CollectedResponse | undefined>(undefined);
  const intervalCollectRef = useRef<NodeJS.Timeout | null>(null);

  const resetCollectedResponse = () => {
    setCollectedResponse(undefined);
  };

  const stopCollectTimer = useCallback(() => {
    if (intervalCollectRef.current) {
      clearInterval(intervalCollectRef.current);
      intervalCollectRef.current = null;
    }
  }, []);

  const resetCollect = useCallback(() => {
    stopCollectTimer();
    resetCollectedResponse();
  }, [stopCollectTimer]);

  const validateBankIdCollect = useCallback(async () => {
    if (!bankIdContent) return;

    const orderRef: OrderRef = { orderRef: bankIdContent.orderRef };
    const response = await collect(orderRef);

    if (!response.isOk()) {
      const msg = typeof response.message === "string" ? response.message : response.message.text;

      setBankIdError(msg);

      return;
    }

    setCollectedResponse(response.data);

    // Continue collecting status until it's no longer pending
    if (response.data?.status !== "pending") {
      stopCollectTimer();
    }
  }, [bankIdContent, stopCollectTimer, setBankIdError]);

  useEffect(() => {
    if (!bankIdContent?.orderRef) return;

    // Set up the interval to call validateBankIdCollect every 2 seconds
    intervalCollectRef.current = setInterval(() => {
      validateBankIdCollect();
    }, 2 * MILLISECONDS_IN_SECOND);

    return () => stopCollectTimer();
  }, [bankIdContent, validateBankIdCollect, stopCollectTimer]);

  useEffect(() => {
    if (collectedResponse?.status === "complete" && bankIdContent?.orderRef) {
      window.location.href = `/?loginToken=${bankIdContent.orderRef}`;
    }
  }, [collectedResponse?.status, bankIdContent?.orderRef]);

  return { collectedResponse, resetCollect };
};
